import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  ISubscribeData,
  ISubscribeAgreementData,
  SubscribeComponent,
} from './subscribe/component/subscribe/subscribe.component';
import { IUProperty, UProperty } from '../../core/interface/umbraco-property';
import { Html } from '../../core/interface/types';
import { PopupService } from 'src/app/shared/components/popup/popup.service';
import { SubscribeModalComponent } from './subscribe-modal/subscribe-modal.component';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { A11yModule } from '@angular/cdk/a11y';
import { SUBSCRIBE_MODULE_CONFIG } from './subscribe/config';
import { SubscribeService } from './subscribe/service/subscribe.service';

export interface ISubscribePanelData {
  description: IUProperty<string>;
  title: IUProperty<string>;
  agreementText: IAgreementTextItem;
  lists: IUProperty<IListItemPanelData>[];
  anchor: IUProperty<string>;
}

export interface IListItemPanelData {
  listId: IUProperty<string>;
  listName: IUProperty<string>;
  groups: IUProperty<IUProperty<{ groupId: string }>[]>;
}

export interface IAgreementTextItem {
  description: IUProperty<Html>;
  name: string;
  title: IUProperty<string>;
  url: string;
}

@Component({
  standalone: true,
  selector: 'ubl-subscribe-panel',
  templateUrl: './subscribe-panel.component.html',
  styleUrls: ['./subscribe-panel.component.less'],
  host: {
    '[class.panel-fixed]': 'popup',
    '[hidden]': 'popup && closed',

    '[attr.role]': 'popup ? "dialog": null',
    '[attr.aria-modal]': 'popup',
  },
  imports: [
    CommonModule,
    SubscribeComponent,
    SharedModule,
    TranslateModule,
    A11yModule,
  ],
  providers: [
    {
      provide: SUBSCRIBE_MODULE_CONFIG,
      useValue: {
        api: '/ubaseline/api',
        agreementsUrl: '/agreements/url',
      },
    },
    SubscribeService,
  ],
})
export class SubscribePanelComponent {
  @Input() data: ISubscribePanelData;
  @Input() popup: boolean = null;

  @Output() close = new EventEmitter();

  closed = false;

  constructor(private popupService: PopupService) {}

  subscribeData: ISubscribeData;
  ngOnInit() {
    let mapped = UProperty.extract<ISubscribePanelData, ISubscribeData>(
      this.data,
      ['title', 'description', 'agreementText', 'lists']
    );

    const { description, title } = UProperty.extract<
      IAgreementTextItem,
      ISubscribeAgreementData
    >(mapped.agreementText as any, ['description', 'title']);

    mapped.agreementText.description = description;
    mapped.agreementText.title = title;
    this.subscribeData = mapped;
  }

  showAgreements(target: HTMLElement) {
    this.popupService.show(SubscribeModalComponent, {
      data: this.subscribeData.agreementText,
      title: this.subscribeData.agreementText.title,
      initiator: target,
    });
  }

  closeHandler() {
    this.closed = true;
    this.close.emit();
  }
}
