import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { ISiteHeaderSettings } from 'src/app/shared/interface/site-settings';
import { INavigationItem } from 'src/app/service/navigation.service';
import { DrawerService } from 'src/app/dynamic-module/lib/drawer/service/drawer.service';
import { Router, NavigationEnd } from '@angular/router';
import { filter, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { IPictureData, PictureData } from 'src/app/dynamic-module/lib/public_api';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.less'],
  encapsulation: ViewEncapsulation.None,
})
export class HeaderComponent implements OnInit {
  @Input() navigation: INavigationItem[];
  @Input() settings: ISiteHeaderSettings;
  logo: IPictureData;
  currentUrl: string = '';

  private alive$ = new Subject();
  constructor(
    public drawerService: DrawerService,
    private router: Router
  ) { }

  ngOnInit()
  {
    this.logo = this.settings.logo;
    this.logo.alt = this.settings.logoAltText;

    this.router.events.pipe(
      filter(ev => ev instanceof NavigationEnd),
      takeUntil(this.alive$)
    ).subscribe((ev: NavigationEnd) => {
      const [currentUrl, hash] = ev.urlAfterRedirects.split('#');

      this.currentUrl = currentUrl;
     !hash && document.body.focus();
    });
  }

  ngOnDestroy()
  {
    this.alive$.next();
    this.alive$.complete();
  }

  onSearch(event, element: HTMLInputElement){
    event.preventDefault();

    if(!element.value) return;
    this.goToSearchPage(element.value);
    element.value = '';
  }

  goToSearchPage(query?: string){
    this.router.navigate([this.settings.searchPageUrl], { fragment: query });
  }
}
