import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { AppConfigService } from './app-config.service';
import { ActivatedRouteSnapshot } from '@angular/router';
import { IPageData } from '../shared/components/abstract-page/abstract-page.component';

const pageAliasesToExcludeFromCache = ['toolboxPage'];

declare let pageData: any;
@Injectable({
  providedIn: 'root',
})
export class PageResolverService {
  private cache = new Map<string, IPageData>();

  constructor(
    private http: HttpClient,
    private appConfigService: AppConfigService
  ) {}

  async resolve(route: ActivatedRouteSnapshot) {
    const path = `/${route.url.join('/')}`;
    let queryParams = this.getQueryAsString(route.queryParams);
    const key = queryParams.length === 0 || queryParams.includes("previewId") ? path : path + queryParams;
    let cached = this.cache.get(key);

    if (!cached) {
      cached = await this.byPath(path, queryParams);
      const utmParams = this.getUtmParams(cached);
      if (Object.keys(utmParams).length === 0 && !pageAliasesToExcludeFromCache.includes(cached?.contentTypeAlias)) {
        this.cache.set(key, cached);
      }
    }

    return cached;
  }

  async byPath(path: string, query: string) {
    const currentHost = this.appConfigService.getHostName().replace(/\/$/, '');
    const host = await this.getHost(currentHost);

    if (currentHost === host) {
      return this.http
        .get(`ubaseline/api/node/getByurl?url=${host}${path}${query}`)
        .pipe(map((res) => this.parseResponse(res)))
        .toPromise();
    } else {
      window.location.href = host + path + query;
    }
  }

  async getHost(currentHost: string) {
    const culture = this.tryGetCulture();
    if (culture) {
      return this.http
        .get(`ubaseline/api/domain/getDomainByCulture?culture=${culture}`)
        .pipe(
          map((res) => {
            return (<any>res).replace(/\/$/, '');
          })
        )
        .toPromise();
    } else {
      return currentHost.replace(/\/$/, '');
    }
  }

  async init() {
    let path = location.pathname;
    let cached;

    if (typeof pageData !== 'undefined') {
      try {

        for (const key in pageData) {
          if (key.length > 0 && key.includes(path)) {
            cached = pageData[key];
          }
        }
      } catch {
        console.error("Can't parse pageData from server")
      }
    }

    if (!cached) {
      cached = await this.byPath(path, '');
    }

    const utmParams = this.getUtmParams(cached);

    if (Object.keys(utmParams).length === 0 && !pageAliasesToExcludeFromCache.includes(cached?.contentTypeAlias)) {
      this.cache.set(path, cached);
    }
  }

  private tryGetCulture() {
    const cultureHash = '#?culture=';
    return window.location.search.indexOf('preview') > -1 &&
      window.location.hash.startsWith(cultureHash)
      ? window.location.hash.split(cultureHash)[1]
      : '';
  }

  private parseResponse(res: any) {
    if (res === null) return res;
    if (!res.isDefaultModel) return res;

    let data = {};

    res['properties'].forEach((prop) => {
      data[prop['alias']] = {
        value: prop['value'],
        editorAlias: prop['editorAlias'],
      };
    });

    return data;
  }

  private getUtmParams(data: IPageData) {
    return data && data.utmConfiguration
      ? data.utmConfiguration.value.params
      : {};
  }

  private getQueryAsString(queryParams: any) {
    if (!queryParams || Object.keys(queryParams).length === 0) {
      return '';
    }

    var query = [];
    Object.keys(queryParams).forEach(function (key) {
      query.push(key + '=' + queryParams[key]);
    });

    return `?${query.join('%26')}`;
  }
}
