import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { SiteSettingsService } from './service/site-settings.service';
import { ISiteSettings } from './shared/interface/site-settings';
import {
  NavigationService,
  INavigationItem,
} from './service/navigation.service';
import { TranslateService } from '@ngx-translate/core';
import { DrawerService } from './dynamic-module/lib/drawer/service/drawer.service';
import { PictureData, IPictureData } from './dynamic-module/lib/public_api';
import { MqService } from './service/mq.service';
import { Router } from '@angular/router';
import { NewsletterPanelService } from './service/newsletter-panel.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less'],
})
export class AppComponent implements OnInit {
  siteSettings: ISiteSettings;
  topNavigation: INavigationItem[];
  mobileNavigation$: any;
  logo: IPictureData;
  isMobile: boolean;
  loaded = false;

  @ViewChild('newsletterRef', { read: ViewContainerRef })
  newsletterRef: ViewContainerRef;

  constructor(
    private siteSettingsService: SiteSettingsService,
    private navigationService: NavigationService,
    private translateService: TranslateService,
    public drawerService: DrawerService,
    private mqService: MqService,
    private router: Router,
    private newsletterPanelService: NewsletterPanelService
  ) {}

  async ngOnInit() {
    this.mqService.mobileDesktop(
      () => (this.isMobile = true),
      () => (this.isMobile = false)
    );
    this.translateService.use('');
    this.siteSettingsService.siteSettings$.subscribe((data) => {
      this.siteSettings = data;
      this.logo = this.siteSettings.headerSettings.logo;
      this.logo.alt = this.siteSettings.headerSettings.logoAltText

      this.newsletterPanelService.init(
        this.siteSettings.newsletterPopupFirstDisplayDelayInMs,
        this.siteSettings.newsletterPopupNextDisplayDelayInMonths,
        this.newsletterRef
      );
    });

    this.navigationService
      .getTopNavigation()
      .toPromise()
      .then((data) => {
        this.topNavigation = data;
      });
    this.mobileNavigation$ = this.navigationService.stakedNavigation$;
  }

  goToSearchPage(query?: string) {
    this.router.navigate([this.siteSettings.headerSettings.searchPageUrl], {
      fragment: query,
    });
  }
}
