<ng-container *ngIf="(!loading || isPrintMode) && pictureData">
  <source
    *ngFor="let item of pictureData.sources"
    [media]="item.media"
    [attr.srcset]="item.srcSet"
    [attr.type]="typeByUrl(item.srcSet)"
  />
  <img
    [ngStyle]="style"
    [src]="pictureData.src"
    [alt]="pictureData.alt"
    [title]="pictureData.alt"
    [attr.width]='width'
    [attr.height]='height'
    (error)="fallbackHandler($event)"
  />
</ng-container>

<div class="loader" *ngIf="loading && !isPrintMode"></div>

<ng-container *ngIf="!loading && !pictureData && !isPrintMode">
  {{ 'image.NoImage' | translate }}
</ng-container>
