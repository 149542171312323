import { HttpClient } from '@angular/common/http';
import { Injectable, ViewContainerRef } from '@angular/core';
import { combineLatest } from 'rxjs';
import { config } from '../app.config';
import {
  ISubscribePanelData,
  SubscribePanelComponent,
} from '../dynamic-module/lib/panel/subscribe-panel/subscribe-panel.component';

const URL = `${config.api}/newsletter/panel`;
const STORAGE_KEY = 'NSS';

@Injectable({
  providedIn: 'root',
})
export class NewsletterPanelService {
  private date: Date;

  constructor(
    private http: HttpClient,
  ) {
    const storageValue = localStorage?.getItem(STORAGE_KEY);

    if (storageValue) {
      this.date = new Date(storageValue);
    }
  }

  init(
    timeoutDelay: number,
    expirationInMonths: number,
    containerRef: ViewContainerRef
  ) {
    const now = new Date();
    const expirationDate = this.getExpirationDate(expirationInMonths);

    if (!expirationDate || expirationDate.getTime() <= now.getTime()) {
      setTimeout(() => {
        this.http.get<ISubscribePanelData>(URL)
          .subscribe((data) => {
            const componentRef =
              containerRef.createComponent<SubscribePanelComponent>(SubscribePanelComponent);

            componentRef.instance.data = data;
            componentRef.instance.popup = true;

            componentRef.instance.close.subscribe(() => {
              localStorage?.setItem(STORAGE_KEY, now.toString());
              containerRef.clear();
            });
          });

      }, timeoutDelay);
    }
  }

  private getExpirationDate(expirationInMonths: number): Date {
    if (this.date && expirationInMonths > 0) {
      const expirationDate = new Date(this.date);
      expirationDate.setMonth(expirationDate.getMonth() + expirationInMonths);

      return expirationDate;
    }
    return null;
  }
}
