import {
  Component,
  ViewContainerRef,
  ViewChild,
  Input,
  OnInit,
  ElementRef,
  Inject,
} from '@angular/core';
import { defaultModel2UProperty } from 'src/app/dynamic-module/lib/mapper/defaultModel2UProperty';
import { IUProperty } from '../../interface/umbraco-property';
import { PanelsService } from '../panels/panels.service';
import { AsynchronouslyInitialisedComponent } from '../asynchronously-initialised/asynchronously-initialised.component';
import { AnimationsService } from 'src/app/service/animations.service';
import { CommonModule } from '@angular/common';
import {
  DYNAMIC_COMPONENTS,
  DynamicComponentManifest,
} from 'src/app/ui/dynamic-component-manifest';
import { IPanelSettings } from '../../interface/panel-settings';

export enum AnimationState {
  Stay = 'stay',
  Hide = 'hide',
  Show = 'show',
}
export interface IPanel {
  contentTypeAlias: string;
  anchor: IUProperty<string>;
  panelSettings?: IPanelSettings;
}

@Component({
  selector: 'app-dynamic-component',
  templateUrl: './dynamic.component.html',
  styleUrls: ['./dynamic.component.less'],
  host: {
    '[class]': 'animationState',
  },
})
export class DynamicComponent
  extends AsynchronouslyInitialisedComponent
  implements OnInit
{
  @Input() data: IPanel;
  animationState: AnimationState = AnimationState.Stay;

  private observer: IntersectionObserver;

  constructor(
    private panelsService: PanelsService,
    private animationsService: AnimationsService,
    private elementRef: ElementRef,
    @Inject(DYNAMIC_COMPONENTS) private panels: DynamicComponentManifest[]
  ) {
    super();
  }

  @ViewChild('component', { read: ViewContainerRef })
  set componentOutlet(containerRef: ViewContainerRef) {
    if (!this.data.contentTypeAlias) {
      this.componentLoaded();
      return;
    }
    this.loadComponentTo(containerRef);
  }

  ngOnInit() {
    if (
      this.animationsService.isPageAnimating &&
      'IntersectionObserver' in window
    ) {
      this.animationState = AnimationState.Hide;

      this.observer = new IntersectionObserver(([entry]) => {
        if (entry.isIntersecting) {
          this.animationState = AnimationState.Show;
          this.observer.unobserve(this.elementRef.nativeElement);
        }
      });

      this.observer.observe(this.elementRef.nativeElement);
    }
  }

  private async loadComponentTo(containerRef: ViewContainerRef) {
    const factory = this.panels.find(
      (panel) => panel.componentId === this.data.contentTypeAlias
    );
    if (!factory) return;

    const component = containerRef.createComponent<any>(await factory.loadChildren());

    this.data = defaultModel2UProperty(this.data as any) as any;

    component.instance.data = this.data;
    this.panelsService.markAsLoaded(component.instance);
    if (component.instance.asyncLoading) {
      component.instance.loadedState$.subscribe(() => this.componentLoaded());
    } else {
      this.componentLoaded();
    }
  }
}
