<header class="header">
  <div class="header__top-bar">
    <a
      href="{{ currentUrl }}#main"
      id="skip-navigation"
      class="skip-navigation"
      >{{ "skipNavigationLabel" | translate }}</a
    >

    <a class="header__profile" href="{{settings.mySagerLink.url}}" target="_blank">
      <i class="icon icon-profile profile-icon"></i>
      <span class="header__profile-label">{{ "profile.Label" | translate }}</span>
    </a>

    <form class="header__search search" role="search" (submit)='onSearch($event, searchInput)'>
      <input class="search__input"
        type="text"
        #searchInput
        [attr.aria-label]="'guideSearchInputLabel' | translate" />
      <button class="search__button" type="submit">{{ "search.ButtonLabel" | translate }}</button>
    </form>
  </div>

  <div class="header__container">
    <a class="header__logo" [routerLink]="'/'" title='Byggeskadefonden'>
      <picture ubl-picture [data]="logo" width='331' height='30'></picture>
    </a>

    <nav class="header__navigation">
      <app-top-level-navigation [items]="navigation"></app-top-level-navigation>
    </nav>
    <button class="mobile-search-btn" type='button' (click)='goToSearchPage()' [attr.aria-label]='"search.ButtonLabel" | translate'>
      <i class="icon icon-search"></i>
    </button>

    <button class="mobile-menu-btn" (click)="drawerService.toggle()" [attr.aria-label]='"mobileMenu" | translate'>
      <i class="icon icon-burger"></i>
    </button>
  </div>

  <ng-content></ng-content>
</header>
