import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { config } from '../app.config';
import { map, publishReplay, refCount } from 'rxjs/operators';
import { ISiteSettings } from '../shared/interface/site-settings';
import { IUProperty } from '../shared/interface/umbraco-property';
import { IButtonData, IPictureData } from '../dynamic-module/lib/public_api';
import { DomSanitizer } from '@angular/platform-browser';
import { Observable } from 'rxjs';

export interface ISiteSettingsResponse {
  headerSubLinks?: any;
  footerLogo?: IPictureData;
  footerItems?: any;
  siteTitle?: string;
  siteLogo?: IPictureData;
  culture: string;
  pageTitleSeparator: string;
  logoAltText: string;
  logo: IPictureData;
  lightLogo: IPictureData;
  logoIcon: IPictureData;
  searchPageUrl: string;
  mySagerLink: IButtonData;
  footer: IFooterSettingsResponse;
  newsletterPopupFirstDisplayDelayInMs: number;
  newsletterPopupNextDisplayDelayInMonths: number;
  pdfLogoBlack: IPictureData;
  pdfLogoRed: IPictureData;
  pdfLogoWhite: IPictureData;
}
export interface IFooterSettingsResponse {
  address: string;
  image: IPictureData;
  contactData: string;
  footerAfterText: string;
  links: { link: IUProperty<IButtonData>; image: IUProperty<IPictureData> }[];
}
@Injectable({
  providedIn: 'root',
})
export class SiteSettingsService {
  siteSettings$: Observable<ISiteSettings>;
  searchPageUrl: string;

  constructor(private http: HttpClient, private sanitizer: DomSanitizer) {
    this.siteSettings$ = this.http
      .get<ISiteSettingsResponse>(`${config.api}/siteSettings/get`)
      .pipe(map(this.mapResponse), publishReplay(1), refCount());
  }

  private mapResponse = (response: ISiteSettingsResponse): ISiteSettings => {
    this.searchPageUrl = response.searchPageUrl;

    return {
      pageTitleSeparator: response.pageTitleSeparator,
      newsletterPopupFirstDisplayDelayInMs:
        response.newsletterPopupFirstDisplayDelayInMs,
      newsletterPopupNextDisplayDelayInMonths:
        response.newsletterPopupNextDisplayDelayInMonths,
      headerSettings: {
        headerSubLinks: response.headerSubLinks,
        siteTitle: response.siteTitle,
        logoAltText: response.logoAltText,
        logo: response.logo,
        searchPageUrl: response.searchPageUrl,
        mySagerLink: response.mySagerLink,
      },
      footerSettings: {
        address: this.sanitizer.bypassSecurityTrustHtml(
          response.footer.address
        ),
        contacts: this.sanitizer.bypassSecurityTrustHtml(
          response.footer.contactData
        ),
        footerLogo: response.footerLogo || response.logo,
        links: response.footer.links,
        footerAfterText: this.sanitizer.bypassSecurityTrustHtml(response.footer?.footerAfterText),
      },
      logoIcon: response.logoIcon,
      pdf: {
        blackLogo: response.pdfLogoBlack,
        redLogo: response.pdfLogoRed,
        whiteLogo: response.pdfLogoWhite,
        lightLogo: response.lightLogo,
      }
    };
  };
}
