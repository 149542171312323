import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import get from "lodash/get";

export interface PanelsServiceState {
  anchor: string;
}
@Injectable({
  providedIn: 'root'
})
export class PanelsService {
  state$ = new BehaviorSubject(null);

  private loadedItems = [];

  markAsLoaded(panel: any)
  {
    try {
      this.state$.next({
        anchor: get(panel, "data.anchor.value")
      });
      this.loadedItems.push(panel);
    } catch (err) {}
  }

  getLastLoadedAnchor()
  {
    const lastLoaded = this.loadedItems[this.loadedItems.length - 1];

    if (lastLoaded) return lastLoaded.data.anchor.value;
  }
}
