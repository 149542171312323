<ng-template #content><ng-content></ng-content></ng-template>

<ng-container [ngSwitch]="true">
   <ng-container *ngSwitchCase="level == 2">
      <h2 class="heading__title"><ng-container *ngTemplateOutlet="content"></ng-container></h2>
   </ng-container>
   <ng-container *ngSwitchCase="level == 3">
      <h3 class="heading__title"><ng-container *ngTemplateOutlet="content"></ng-container></h3>
   </ng-container>
   <ng-container *ngSwitchCase="level == 4">
      <h4 class="heading__title"><ng-container *ngTemplateOutlet="content"></ng-container></h4>
   </ng-container>
   <ng-container *ngSwitchCase="level == 5">
      <h5 class="heading__title"><ng-container *ngTemplateOutlet="content"></ng-container></h5>
   </ng-container>
   <ng-container *ngSwitchDefault>
      <h1 class="heading__title"><ng-container *ngTemplateOutlet="content"></ng-container></h1>
   </ng-container>
</ng-container>

<span class="heading__subtitle" *ngIf="note">{{ note }}</span>

