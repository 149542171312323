import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StackedNavigationComponent } from './stacked-navigation.component';
import { LinkModule } from 'src/app/dynamic-module/lib/public_api';

@NgModule({
  declarations: [StackedNavigationComponent],
  imports: [
    CommonModule,
    LinkModule
  ],
  exports: [StackedNavigationComponent]
})
export class StackedNavigationModule { }
