import { Injectable, Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Router, Route } from '@angular/router';
import { config } from '../app.config';
import { PageResolverService } from './page-resolver.service';

declare let appRoutes;
@Injectable({
  providedIn: 'root',
})
export class RouteConfigService {
  constructor(private http: HttpClient, private injector: Injector) {}

  async getRoutes() {
    let routes;
    if (typeof appRoutes !== 'undefined') {
      routes = this.mapRoutes(appRoutes);
    } else {
      routes = await this.http
        .get('/ubaseline/api/node/GetRoutes')
        .pipe(map((res: any) => this.mapRoutes(res) as Route[]))
        .toPromise();
    }

    const router = this.injector.get(Router);
    router.config = routes;
  }

  private mapRoutes(config: { url: string; contentTypeAlias: string }[]) {
    let routes = [];

    routes.push({
      path: 'guide-pdf',
      loadChildren: () =>
        import('src/app/ui/pages/guide-pdf/guide-pdf.module').then(
          (m) => m.GuidePdfModule
        ),
    });
    routes.push({
      path: 'not-found',
      loadChildren: () =>
        import('src/app/ui/pages/not-found/not-found.module').then(
          (m) => m.NotFoundModule
        ),
    });

    config.forEach((el) => {
      if (!this.aliasToComponent(el.contentTypeAlias)) return;

      routes.push({
        path: el.url.slice(1, el.url.length - 1),
        loadChildren: this.aliasToComponent(el.contentTypeAlias),
        resolve: { data: PageResolverService },
      });
    });
    
    routes.push({ path: '**', redirectTo: 'not-found' });
    return routes;
  }

  private aliasToComponent(alias: string) {
    const basePages = {
      contentPage: () =>
        import('src/app/ui/pages/content/content.module').then(
          (m) => m.ContentModule
        ),
      homePage: () =>
        import('src/app/ui/pages/home/home.module').then((m) => m.HomeModule),
    };

    const map = Object.assign(config.pages, basePages);

    return map[alias] || map.contentPage;
  }
}
