<nav class="navigation" *ngIf="items?.length">
  <ng-container *ngFor="let item of items" >
    <a class="navigation__item"
      *ngIf="item.url !== '/'"
      routerLinkActive="navigation__item--active"
      [routerLink]="item.url"><span>{{ item.title }}</span></a>

    <a class="navigation__item"
      *ngIf="item.url === '/'"
      routerLinkActive="navigation__item--active"
      [routerLinkActiveOptions]="{exact: true}"
      [routerLink]="item.url"><span>{{ item.title }}</span></a>
  </ng-container>
</nav>
