import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, Input } from '@angular/core';
import { ILink } from '../../core/interface/link';

@Component({
  selector: 'ubl-link',
  templateUrl: './link.component.html',
  styleUrls: ['./link.component.less'],
})
export class LinkComponent {
  @Input() data: ILink;
  @Input() download: boolean;
  @Input() label: string;

  @Input()
  get noExternalIcon(): boolean {
    return this._noExternalIcon;
  }

  set noExternalIcon(val: boolean) {
    this._noExternalIcon = coerceBooleanProperty(val);
  }

  private _noExternalIcon = false;
}
