import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnInit,
} from '@angular/core';
import {
  IImageStyle,
  IPictureData,
  IPictureFallbackEvent,
} from '../../core/interface/picture';
import { isString } from '../../core/utils/strings/is-string';
import { isDevModeAndNoData } from './helper/is-dev-mode-and-no-data';
import { isInPictureDataNotFallback } from './helper/is-in-picture-data-not-fallback';

@Component({
  selector: 'picture[ubl-picture]',
  templateUrl: './picture.component.html',
  styleUrls: ['./picture.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    '[style.paddingBottom.%]': 'ratio * 100',
    '[class.picture--loading]': 'loading',
    '[class.picture--fail]': 'failed',
    '[class.picture--ratio]': '!!ratio',
    '[class.picture--fallback]': 'isFallback',
  },
})
export class PictureComponent implements OnInit, AfterViewInit {
  @Input() data: IPictureData | string;
  @Input() scale = 100;
  @Input() ratio: number;
  @Input() fallback: string;

  @Input() width: number;
  @Input() height: number;

  @Input()
  get lazy(): boolean {
    return this._lazy;
  }
  set lazy(value: boolean) {
    this._lazy = coerceBooleanProperty(value);
  }
  private _lazy: boolean;

  @Input()
  get wide(): boolean {
    return this._wide;
  }
  set wide(value: boolean) {
    this._wide = coerceBooleanProperty(value);
  }

  private _wide = false;

  @Input() alt = '';

  isPrintMode = matchMedia("print");
  isFallback = false;
  failed = false;
  loading = true;
  pictureData: IPictureData;
  style: IImageStyle;
  private io: IntersectionObserver;

  constructor(public elementRef: ElementRef, public cdr: ChangeDetectorRef) { }

  ngOnInit() {
    if (isDevModeAndNoData(this.data, this.fallback))
      console.error(
        'Please provide at least one of string or PictureData or fallback. In production will be used empty string as src therefore alt text will be used'
      );

    if (!this.lazy || this.isPrintMode) this.loadPicture();
  }

  ngAfterViewInit() {
    if (this.lazy) {
      if (!('IntersectionObserver' in window)) {
        this.loadPicture();
        return;
      }

      setTimeout(() => {
        this.io = new IntersectionObserver(
          ([entry]) => {
            if (entry.isIntersecting) {
              this.loadPicture();
            }
          },
          {
            root: null,
            threshold: 0,
          }
        );

        this.io.observe(this.elementRef.nativeElement);
      }, 0);
    }
  }

  loadPicture() {
    if (!this.loading) return;

    this.pictureData = this.getPictureData();
    this.failed = !this.hasFallback && !this.data;

    this.updateInlineStyles();
    this.loading = false;
    this.cdr.detectChanges();

    const pictureSupported = !!window.HTMLPictureElement;
    if (!pictureSupported) {
      import('picturefill');
    }

    if (this.lazy && this.io) this.io.unobserve(this.elementRef.nativeElement);
  }

  hasFallback() {
    return isInPictureDataNotFallback(this.pictureData, this.fallback);
  }

  updateInlineStyles() {
    let width = 'auto';
    const maxWidth = '100%';

    if (this.wide) {
      width = '100%';
    } else if (this.scale < 100) {
      width = `${this.scale}%`;
    }

    this.style = { width, maxWidth };
  }

  reset() {
    this.pictureData = null;
  }

  fallbackHandler(event: IPictureFallbackEvent) {
    if (
      !this.isFallback &&
      isInPictureDataNotFallback(this.pictureData, this.fallback)
    ) {
      this.isFallback = true;
      this.pictureData.sources = [];
      this.pictureData.src = this.fallback;
    } else {
      this.failed = true;
      this.reset();
    }
  }

  private getPictureData(): IPictureData {
    if (this.data && !isString(this.data)) {

      if (!this.data.sources) this.data.sources = [];

      this.data.sources.forEach(source => {
        if (source.srcSet && !source.srcSet.includes("mode")) {
          source.srcSet = source.srcSet + '&mode=crop'
        }
      });

      const desktopSource = this.data.sources.find(source => source.alias === 'desktop' && source.media)
      
      if (desktopSource) {
        this.data.sources.unshift({...desktopSource, media: 'print'})
        if (this.isPrintMode) this.data.src = desktopSource.srcSet;
      }

      return this.data;
    }

    if (this.data && isString(this.data)) {
      return {
        alt: this.alt,
        src: this.data,
        sources: [],
      };
    }

    if (this.fallback && !this.data) {
      this.isFallback = true;

      return {
        alt: this.alt,
        src: this.fallback,
        sources: [],
      };
    }
  }

  typeByUrl(url: string) {
    return url?.indexOf('format=webp') !== -1 ? 'image/webp' : null;
  }
}
