import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PanelsComponent } from './components/panels/panels.component';
import { ButtonComponent } from './components/button/button.component';
import { DynamicComponent } from './components/dynamic/dynamic.component';
import { AbstractPageComponent } from './components/abstract-page/abstract-page.component';
import { RouterModule } from '@angular/router';
import { ClickOutsideDirective } from './directive/click-outside.directive';
import { HeadingComponent } from './components/heading/heading.component';
import { TranslateModule } from '@ngx-translate/core';
import { LinkModule } from '../dynamic-module/lib/public_api';
import { StackedNavigationModule } from '../ui/main-layout-components/stacked-navigation/stacked-navigation.module';
import { WrapWithContainerDirective } from './directive/wrap-with-container.directive';

const components = [
  PanelsComponent,
  ButtonComponent,
  DynamicComponent,
  AbstractPageComponent,
  ClickOutsideDirective,
  HeadingComponent,
  WrapWithContainerDirective
];

@NgModule({
  declarations: components,
  imports: [
    CommonModule,
    RouterModule,
    LinkModule,
    TranslateModule,
    StackedNavigationModule,
  ],
  exports: components,
})
export class SharedModule {}
