import { Component, Input, Output, EventEmitter } from '@angular/core';
import { DrawerService, Link } from 'src/app/dynamic-module/lib/public_api';
export interface IStackedNavigationItem {
  isActive: boolean;
  children: IStackedNavigationItem[];
  isAdditionalLink: boolean;
  isExpanded: boolean;
  linkTarget: string;
  title: string;
  url: string;
}
@Component({
  selector: 'ul[stacked-navigation]',
  templateUrl: './stacked-navigation.component.html',
  styleUrls: ['./stacked-navigation.component.less'],
  host: {class: 'stacked-navigation'},
})
export class StackedNavigationComponent {
  @Input('stacked-navigation') data: IStackedNavigationItem[];

  constructor(
    public drawerService: DrawerService
  ) {}

  Link = Link;
  handleLinkClick(item){
    this.drawerService.close();
  }

  handleClick(item: IStackedNavigationItem)
  {
    if (item.isExpanded) return item.isExpanded = false;

    this.data.forEach(i => i.isExpanded = false);
    item.isExpanded = true;

  }
}
