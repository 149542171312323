import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class HttpInterceptorService implements HttpInterceptor {
  constructor() {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const isGetByUrl = request.url.toLocaleLowerCase().includes('getbyurl?url=');

    const headers: any = {
      ubhReferrer: isGetByUrl ? request.url.toLocaleLowerCase().split('getbyurl?url=')?.[1] : window.location.href,
    };

    const modifiedReq = request?.clone({
      setHeaders: headers,
    });

    if (modifiedReq.method.toUpperCase() !== 'GET' && modifiedReq.method.toUpperCase() !== 'POST') {
      return next.handle(modifiedReq).pipe(
        // tap(
        //   (res) => {},
        //   (err) => {
        //     // if (err instanceof HttpResponse && err.status === 401) {
        //     //   this.authService.loginRedirect();
        //     // }
        //     // return err;
        //   }
        // )
      );
    }

    return next.handle(modifiedReq).pipe(
      // tap(
      //   (res: HttpEvent<any>) => {
      //     if (res instanceof HttpResponse && res.body !== undefined && res.status === 200) {
      //       return { ...res, body: umbracoMapper(res.body) };
      //     }
      //     return res;
      //   },
      //   (err) => {
      //     // if (err instanceof HttpErrorResponse && err.status === 401) {
      //     //   this.authService.loginRedirect();
      //     // }
      //     // return err;
      //   }
      // )
    );
  }
}
