import { NgModule, APP_INITIALIZER, ErrorHandler, LOCALE_ID } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouteConfigService } from './service/route-config.service';
import { HeaderComponent } from './ui/main-layout-components/header/header.component';
import { FooterComponent } from './ui/main-layout-components/footer/footer.component';
import { MainComponent } from './ui/main-layout-components/main/main.component';
import { DYNAMIC_COMPONENTS, dynamicComponents } from './ui/dynamic-component-manifest';
import { TopLevelNavigationComponent } from './ui/main-layout-components/top-level-navigation/top-level-navigation.component';
import { SharedModule } from './shared/shared.module';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslationsLoader } from './service/translations-loader';
import { AppErrorHandler } from './app-error-handler';
import { AUTOSUGGEST_CONFIG } from './dynamic-module/lib/panel/autosuggest-panel/config';
import { config } from './app.config';
import { DrawerModule } from './dynamic-module/lib/drawer/drawer.module';
import { PictureModule } from './dynamic-module/lib/ui-kit/picture/picture.module';
import { LinkModule } from './dynamic-module/lib/public_api';
import { ScrollTopModule } from './shared/components/scroll-top/scroll-top.module';
import { PageResolverService } from './service/page-resolver.service';
import { MobileMenuModule } from './shared/components/mobile-menu/mobile-menu.module';
import { BrowserModule } from '@angular/platform-browser';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { HttpInterceptorService } from './service/http-interceptor.service';

export function initApp(
  routeConfigService: RouteConfigService,
  pageResolver: PageResolverService
) {
  return async () => {
    if (!/^\/guide\-pdf\//.test(window.location.pathname)) {
      await pageResolver.init();
    }
    
    return routeConfigService.getRoutes();
  };
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    MainComponent,
    TopLevelNavigationComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    SharedModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: TranslationsLoader,
        deps: [HttpClient],
      },
    }),
    DrawerModule.forRoot(),
    PictureModule,
    LinkModule,
    ScrollTopModule,
    MobileMenuModule,
    NoopAnimationsModule
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initApp,
      deps: [RouteConfigService, PageResolverService],
      multi: true,
    },
    {
      provide: ErrorHandler,
      useClass: AppErrorHandler,
    },
    {
      provide: AUTOSUGGEST_CONFIG,
      useValue: {
        endPoint: config.autoSuggestApi,
        page: 0,
        searchPageUrl: 'search-result-page',
      },
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true,
    },
    {
      provide: DYNAMIC_COMPONENTS,
      useValue: dynamicComponents
    },
    {provide: LOCALE_ID, useValue: 'da-DK'},
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
