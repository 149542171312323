import { InjectionToken } from "@angular/core";

export interface DynamicComponentManifest {
  componentId: string;
  loadChildren: () => Promise<any>;
}

export const DYNAMIC_COMPONENTS = new InjectionToken<DynamicComponentManifest[]>('DYNAMIC_COMPONENTS');

const baseComponents: DynamicComponentManifest[] = [
  {
    componentId: 'heroPanel',
    loadChildren: () => import('../dynamic-module/hero-panel/hero-panel.component').then(m => m.HeroPanelComponent)
  },
  {
    componentId: 'articlePanel',
    loadChildren: () => import('../dynamic-module/article-panel/article-panel.component').then(m => m.ArticlePanelComponent)
  },
  {
    componentId: 'iconPanel',
    loadChildren: () => import('../dynamic-module/icon-panel/icon-panel.component').then(m => m.IconPanelComponent)
  },
  {
    componentId: 'faqPanel',
    loadChildren: () => import('../dynamic-module/faq-panel/faq-panel.component').then(m => m.FaqPanelComponent)
  },
  {
    componentId: 'spotPanel',
    loadChildren: () => import('../dynamic-module/spot-panel/spot-panel.component').then(m => m.SpotPanelComponent)
  },
  {
    componentId: 'imagePanel',
    loadChildren: () => import('../dynamic-module/image-panel/image-panel.component').then(m => m.ImagePanelComponent)
  },
  {
    componentId: 'videoPanel',
    loadChildren: () => import('../dynamic-module/video-panel/video-panel.component').then(m => m.VideoPanelComponent)
  },
  {
    componentId: 'quotePanel',
    loadChildren: () => import('../dynamic-module/lib/panel/quote-panel/quote-panel.component').then(m => m.QuotePanelComponent)
  },
  {
    componentId: 'topImagePanel',
    loadChildren: () => import('../dynamic-module/top-image-panel/top-media-panel.component').then(m => m.TopMediaPanelComponent)
  },
  {
    componentId: 'imageAdvancedPanel',
    loadChildren: () => import('../dynamic-module/advanced-image-panel/advanced-image-panel.component').then(m => m.AdvancedImagePanelComponent)
  },
  {
    componentId: 'advancedSpotPanel',
    loadChildren: () => import('../dynamic-module/advanced-spot-panel/advanced-spot-panel.component').then(m => m.AdvancedSpotPanelComponent)
  },
  {
    componentId: 'autoSuggest',
    loadChildren: () => import('../dynamic-module/autosuggest-panel/autosuggest-panel.component').then(m => m.AutosuggestPanelComponent)
  },
  {
    componentId: 'contactPanel',
    loadChildren: () => import('../dynamic-module/contact-panel/contact-panel.component').then(m => m.ContactPanelComponent)
  },
  {
    componentId: 'mailchimpPanel',
    loadChildren: () => import('../dynamic-module/lib/panel/subscribe-panel/subscribe-panel.component').then(m => m.SubscribePanelComponent)
  },
  {
    componentId: 'newsPanel',
    loadChildren: () => import('../dynamic-module/news-panel/news-panel.component').then(m => m.NewsPanelComponent)
  },
  {
    componentId: 'tablePanel',
    loadChildren: () => import('../dynamic-module/table-panel/table-panel.component').then(m => m.TablePanelComponent)
  },
  {
    componentId: 'documentLibraryPanel',
    loadChildren: () => import('../dynamic-module/lib/panel/document-library-panel/document-library-panel.component').then(m => m.DocumentLibraryPanelComponent)
  },
  {
    componentId: 'dividerPanel',
    loadChildren: () => import('../dynamic-module/lib/panel/divider-panel/divider-panel.component').then(m => m.DividerPanelComponent)
  },
  {
    componentId: 'linksPanel',
    loadChildren: () => import('../dynamic-module/bsf-links-panel/bsf-links-panel.component').then(m => m.BsfLinksPanelComponent)
  },
  {
    componentId: 'textPanel',
    loadChildren: () => import('../dynamic-module/text-panel/text-panel.component').then(m => m.TextPanelComponent)
  },
  {
    componentId: 'googleMapPanel',
    loadChildren: () => import('../dynamic-module/map-panel/map-panel.component').then(m => m.MapPanelComponent)
  },
  {
    componentId: 'juicerSocialPanel',
    loadChildren: () => import('../dynamic-module/social-panel/social-panel.component').then(m => m.SocialPanelComponent)
  },
  {
    componentId: 'defaultPanel',
    loadChildren: () => import('../dynamic-module/default-panel/default-panel.component').then(m => m.DefaultPanelComponent)
  },
  {
    componentId: 'twoImagePanel',
    loadChildren: () => import('../dynamic-module/two-image-panel/two-image-panel.component').then(m => m.TwoImagePanelComponent)
  },
  {
    componentId: 'databasePanel',
    loadChildren: () => import('../dynamic-module/database-panel/database-panel.component').then(m => m.DatabasePanelComponent)
  },
  {
    componentId: 'downloadPanel',
    loadChildren: () => import('../dynamic-module/download-panel/download-panel.component').then(m => m.DownloadPanelComponent)
  },
  {
    componentId: 'factsPanel',
    loadChildren: () => import('../dynamic-module/facts-panel/facts-panel.component').then(m => m.FactsPanelComponent)
  },
  {
    componentId: 'relatedContentPanel',
    loadChildren: () => import('../dynamic-module/related-content-panel/related-content-panel.component').then(m => m.RelatedContentPanelComponent)
  },
  {
    componentId: 'eventPanel',
    loadChildren: () => import('../dynamic-module/event-panel/event-panel.component').then(m => m.EventPanelComponent)
  },
  {
    componentId: 'teaserPanel',
    loadChildren: () => import('../dynamic-module/teaser-panel/teaser-panel.component').then(m => m.TeaserPanelComponent)
  },
  {
    componentId: 'teaserAdvancedPanel',
    loadChildren: () => import('../dynamic-module/teaser-advanced-panel/teaser-advanced-panel.component').then(m => m.TeaserAdvancedPanelComponent)
  },
  {
    componentId: 'multipleSpotPanel',
    loadChildren: () => import('../dynamic-module/multiple-spot-panel/multiple-spot-panel.component').then(m => m.MultipleSpotPanelComponent)
  },
  {
    componentId: 'mediaSpotPanel',
    loadChildren: () => import('../dynamic-module/media-spot-panel/media-spot-panel.component').then(m => m.MediaSpotPanelComponent)
  },
  {
    componentId: 'threeDimensionalPanel',
    loadChildren: () => import('../dynamic-module/three-dimensional-panel/three-dimensional-panel.component').then(m => m.ThreeDimensionalPanelComponent)
  },
  {
    componentId: 'listSubpagesPanel',
    loadChildren: () => import('../dynamic-module/subpages-list-panel/subpages-list-panel.component').then(m => m.SubpagesListPanelComponent)
  },
  {
    componentId: 'quoteWithHorizontalImagePanel',
    loadChildren: () => import('../dynamic-module/quote-with-image-panel/quote-with-image-panel.component').then(m => m.QuoteWithImagePanelComponent)
  },
  {
    componentId: 'quoteWithVerticalImagePanel',
    loadChildren: () => import('../dynamic-module/quote-with-image-panel/quote-with-image-panel.component').then(m => m.QuoteWithImagePanelComponent)
  },
  {
    componentId: 'pageBreakPanel',
    loadChildren: () => import('../dynamic-module/page-break-panel/page-break-panel.component').then(m => m.PageBreakPanelComponent)
  },
  {
    componentId: 'customHtmlPanel',
    loadChildren: () => import('../dynamic-module/custom-html-panel/custom-html-panel.component').then(m => m.CustomHtmlPanelComponent)
  },
];

export const dynamicComponents = [...baseComponents];
