import {
  Injectable,
  Type,
  ComponentFactoryResolver,
  ComponentFactory,
  ApplicationRef,
} from '@angular/core';
import {
  ContainerComponent,
  IPopupContext,
} from './components/container/container.component';

@Injectable({
  providedIn: 'root',
})
export class PopupService {
  private container: HTMLDivElement;
  popupFactory: ComponentFactory<ContainerComponent>;

  constructor(
    private appRef: ApplicationRef,
    private componentFactoryResolver: ComponentFactoryResolver
  ) {
    this.setup();
  }

  show<T>(
    component: Type<T>,
    context?: IPopupContext,
    lazyComponentFactory?: ComponentFactoryResolver
  ) {
    const popupRef = this.appRef.bootstrap(this.popupFactory, this.container);

    const componentRef = popupRef.instance.create(
      component,
      context,
      lazyComponentFactory
    );

    return componentRef;
  }

  private setup() {
    this.popupFactory =
      this.componentFactoryResolver.resolveComponentFactory(ContainerComponent);

    this.container = document.createElement('div');
    
    document.body.appendChild(this.container);
  }
}
