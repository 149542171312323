<app-header
  [navigation]="topNavigation"
  [settings]="siteSettings?.headerSettings"
  *ngIf="siteSettings && topNavigation"
></app-header>

<app-main>
  <router-outlet></router-outlet>
</app-main>

<footer
  app-footer
  *ngIf="siteSettings"
  [settings]="siteSettings?.footerSettings"
></footer>

<ubl-drawer *ngIf="isMobile">
  <div class="drawer__top">
    <a
      [routerLink]="'/'"
      class="drawer__logo"
      title="Byggeskadefonden"
      *ngIf="logo"
    >
      <picture ubl-picture [data]="logo" width="331" height="30"></picture>
    </a>
    <div class="drawer__buttons">
      <button
        class="mobile-search-btn"
        type="button"
        (click)="drawerService.close(); goToSearchPage()"
        [attr.aria-label]="'search.ButtonLabel' | translate"
      >
        <i class="icon icon-search"></i>
      </button>
      <i
        action="close"
        class="icon icon-close"
        (click)="drawerService.close()"
      ></i>
    </div>
  </div>

  <ng-container *ngIf="mobileNavigation$ | async as mobNavigation">
    <app-mobile-menu [data]="mobNavigation"></app-mobile-menu>
  </ng-container>

  <a
    *ngIf="siteSettings"
    class="profile"
    href="{{ siteSettings.headerSettings.mySagerLink.url }}"
    target="_blank"
  >
    <i class="icon icon-profile profile-icon"></i>
    <span class="header__profile-label">{{ 'profile.Label' | translate }}</span>
  </a>
</ubl-drawer>

<button scroll-top [attr.aria-label]="'scrollToTop' | translate">
  <i class="icon icon-chevron-up"></i>
</button>

<ng-template #newsletterRef></ng-template>
