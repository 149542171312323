import { Directive, ElementRef, OnInit  } from '@angular/core';

@Directive({
  selector: '[wrapWithContainer]'
})
export class WrapWithContainerDirective implements OnInit {
  constructor(private hostRef: ElementRef) {
  }

  ngOnInit(): void {
    const titles = this.hostRef?.nativeElement?.querySelectorAll('h2, h3');
    titles?.forEach((title: HTMLHeadingElement) => {
      const nextTag: any = title.nextElementSibling;
      const nextTagName: string = title.nextElementSibling?.tagName?.toLocaleLowerCase();

      if (nextTagName === 'ul' ||
        nextTagName === 'ol' ||
        nextTagName === 'p' ||
        nextTagName === 'h3' ||
        nextTagName === 'h2'
      ) {
        const div = document.createElement('div');
        div.classList.add('avoid-break');
        // div.style.pageBreakInside = 'avoid';
        // div.style.outline = '1px dotted blue';

        if (nextTag.innerText && nextTag.innerHTML !== '&nbsp;') {
          div.appendChild(title.cloneNode(true));
          div.appendChild(nextTag.cloneNode(true));

          if (nextTagName === 'h3' || nextTagName === 'h2') {
            if (nextTag.nextElementSibling?.innerText && nextTag.nextElementSibling?.innerHTML !== '&nbsp;') {
              div.appendChild(nextTag.nextElementSibling.cloneNode(true));
              nextTag.nextElementSibling.remove();
            }
          }

          title.replaceWith(div);
          nextTag.remove();
        }
      }
    });
  }
}
