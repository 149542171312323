import { Component, OnInit, Injector, Renderer2 } from '@angular/core';
import { Subject, combineLatest } from 'rxjs';
import { PageResolverService } from 'src/app/service/page-resolver.service';
import { Title, DomSanitizer } from '@angular/platform-browser';
import { SEOService, IPageMetaData } from 'src/app/service/seo.service';
import { Location, ViewportScroller } from '@angular/common';
import { IUProperty } from '../../interface/umbraco-property';
import { SiteSettingsService } from 'src/app/service/site-settings.service';
import { ISiteSettings } from '../../interface/site-settings';
import get from 'lodash/get';
import { ActivatedRoute, Router } from '@angular/router';
import { first, filter, takeUntil } from 'rxjs/operators';
import { PanelsService } from '../panels/panels.service';
import { IBreadcrumbItem } from 'src/app/ui/main-layout-components/breadcrumbs/breadcrumbs.component';
import { NavigationService } from 'src/app/service/navigation.service';
import { DrawerService } from 'src/app/dynamic-module/lib/public_api';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { TranslateService } from '@ngx-translate/core';
import { IArea, IGrid } from '../grid/grid.component';
import { getEmptyGrid } from '../../helpers/grid.helper';

export interface IPageData {
  title: IUProperty<string>;
  url: string;
  metaData: IPageMetaData;
  panels: IUProperty<any[]>;
  grid: IUProperty<IGrid>;
  utmConfiguration: any;
  asidePanels: IUProperty<any[]>;
  breadcrumbs?: IBreadcrumbItem[];
  leftNavigation?: ILeftNavigation;
  tags: string[];
  name: string;
  contentTypeAlias: string;
  redirectSettings: any;
}
export interface ILeftNavigation {
  sectionTitle: string;
  items: ILeftNavigationItem[];
}

export interface ILeftNavigationItem {
  isActive: boolean;
  children: ILeftNavigationItem[];
  isAdditionalLink: boolean;
  isExpanded: boolean;
  linkTarget: string;
  title: string;
  url: string;
}

@Component({
  selector: 'app-abstract-page',
  template: '',
  styles: [''],
})
export class AbstractPageComponent implements OnInit {
  data: IPageData = null;
  protected defaultTitle = '';
  protected alive$ = new Subject();

  constructor(
    public sz: DomSanitizer,
    protected pageResolverService: PageResolverService,
    protected titleService: Title,
    protected seoService: SEOService,
    protected location: Location,
    protected injector: Injector,
    protected siteSettingsService: SiteSettingsService,
    protected route: ActivatedRoute,
    protected viewportScroller: ViewportScroller,
    protected panelsService: PanelsService,
    protected router: Router,
    protected navigationService: NavigationService,
    protected drawerService: DrawerService,
    protected liveAnnouncer: LiveAnnouncer,
    protected translate: TranslateService,
    protected renderer: Renderer2
  ) {}

  async ngOnInit() {
    this.data = JSON.parse(JSON.stringify(this.route.snapshot.data.data)) as IPageData;

    const utmParams = this.getUtmParams(this.data);
    if (Object.keys(utmParams).length > 0) {
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: utmParams,
        queryParamsHandling: 'merge',
      });
    }

    this.siteSettingsService.siteSettings$.subscribe((siteSettings) => {
      this.titleService.setTitle(
        this.resolvePageTitle(this.data, siteSettings)
      );
    });

    if (this.data !== null) {
      this.seoService.addTags(this.data.metaData);
      this.data.url && this.seoService.addCanonicalURL(this.data.url);
    }

    this.viewportScroller.scrollToPosition([0, 0]);
    this.handleAnchor();

    this.navigationService.mobileNavigation$.subscribe((nav) => {
      this.navigationService.setDrawerNavigation(nav);
    });
  }

  handleAnchor() {
    const anchor$ = this.route.fragment.pipe(first());
    const lastLoadedPanelAnchor$ = this.panelsService.state$.pipe(
      filter((i) => i !== null)
    );
    const targetAnchor$ = combineLatest(
      anchor$,
      lastLoadedPanelAnchor$,
      (targetAnchor, lastLoadedPanel) => {
        if (targetAnchor === lastLoadedPanel.anchor) return targetAnchor;
      }
    ).pipe(takeUntil(this.alive$));

    targetAnchor$.subscribe((fragment: string) => {
      this.handleInitialAnchor(fragment);
    });
  }

  ngOnDestroy() {
    this.alive$.next();
    this.alive$.complete();
    this.drawerService.close();
  }

  private resolvePageTitle(data: IPageData, settings: ISiteSettings): string {
    let first =
      get(data, 'metaData.metaTitle.value') || get(data, 'title.value') || '';
    let last = get(settings, 'headerSettings.siteTitle');
    let separator = settings.pageTitleSeparator
      ? ` ${settings.pageTitleSeparator} `
      : ' | ';

    this.liveAnnouncer.announce(`${first} ${this.translate.instant('common.PageLoaded')}`);
    return [first, last].filter((i) => i).join(separator);
  }

  private handleInitialAnchor(anchor: string) {
    if (!anchor) return;
    this.viewportScroller.scrollToAnchor(anchor);
  }

  private getUtmParams(data: IPageData) {
    return data && data.utmConfiguration
      ? data.utmConfiguration.value.params
      : {};
  }

  addPanelsToMainColumn(
    panels: any[],
    addTo: 'top' | 'bottom',
    row: number = 0,
    grid?: IGrid,
    cssClass?: string,
  ) {
    this.addEmptyGridIfNeeded();

    let mainArea: any = this.getMainGridArea(row, grid);

    panels.forEach(panel => {
      mainArea.items[addTo === 'bottom' ? 'push' : 'unshift']({
        areas: [],
        columnSpan: mainArea.items && mainArea.items[0] && mainArea.items[0].columnSpan || 12,
        node: panel,
        rowSpan: 1,
        cssClass
      });
    })
  }

  addPanelsToSecondaryColumn(
    panels: any[],
    addTo: 'top' | 'bottom',
    row: number = 0,
    grid?: IGrid,
    cssClass?: string,
  ) {
    this.addEmptyGridIfNeeded();

    let secondaryArea: any = this.getSecondaryGridArea(row, grid);

    panels.forEach(panel => {
      secondaryArea.items[addTo === 'bottom' ? 'push' : 'unshift']({
        areas: [],
        columnSpan: secondaryArea.items && secondaryArea.items[0] && secondaryArea.items[0].columnSpan || 12,
        node: panel,
        rowSpan: 1,
        cssClass
      });
    })
  }

  getMainGridArea(row: number = 0, grid?: IGrid) {
    let mainArea: IArea = null;
    (grid || this.data.grid?.value).rows[row].areas.forEach(area => {
      if (!mainArea || mainArea?.columnSpan < area.columnSpan) {
        mainArea = area;
      }
    });
    return mainArea;
  }

  getSecondaryGridArea(row: number = 0, grid?: IGrid) {
    let secondaryArea: IArea = null;
    (grid || this.data.grid?.value).rows[row].areas.forEach(area => {
      if (!secondaryArea || secondaryArea?.columnSpan > area.columnSpan) {
        secondaryArea = area;
      }
    });
    return secondaryArea;
  }

  private addEmptyGridIfNeeded() {
    if (!this.data.grid || !this.data.grid.value) {
      this.data.grid = {propertyEditorAlias: '', alias: '', value: getEmptyGrid()}
    }
  }
}
