import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

@NgModule({
  imports: [RouterModule.forRoot([{
    path: 'guide-pdf',
    loadChildren: () =>
      import('src/app/ui/pages/guide-pdf/guide-pdf.module').then(
        (m) => m.GuidePdfModule
      ),
  }], {})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
