<div class="footer__before">
  <a [routerLink]="'/'" class="footer__logo">
    <img
      *ngIf="settings"
      [src]="settings.footerLogo?.src"
      [alt]="settings.footerLogo?.alt || 'logo'"
      width="363"
      height="24"
    />
  </a>
</div>

<div class="footer__content" *ngIf="settings">
  <div class="address" [innerHTML]="settings.address"></div>
  <div class="contacts" [innerHTML]="settings.contacts"></div>

  <ul class="links" *ngIf="settings.links?.length">
    <li class="links__item" *ngFor="let link of settings.links">
      <img
        *ngIf="link?.image?.value"
        class="links__item-image"
        [src]="link.image.value.src"
        [alt]="
          link.image.value?.alt ? link.image.value.alt : link.link.value.name
        "
        [width]="link.image.value.width"
        [height]="link.image.value.height"
      />

      <ubl-link
        *ngIf="link?.link?.value"
        [data]="Link.fromButtonData(link.link.value)"
      >
        {{ link.link.value.name }}
      </ubl-link>
    </li>
  </ul>
</div>

<div class="footer__after">
  <div class="copy rte" [innerHTML]="settings?.footerAfterText"></div>
</div>
