import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MobileMenuComponent } from './mobile-menu.component';
import { StackedNavigationModule } from 'src/app/ui/main-layout-components/stacked-navigation/stacked-navigation.module';

@NgModule({
  declarations: [MobileMenuComponent],
  imports: [CommonModule, StackedNavigationModule],
  exports: [MobileMenuComponent],
})
export class MobileMenuModule {}
