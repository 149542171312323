import {
  Component,
  HostBinding,
  Input,
} from '@angular/core';
import { FormControlName } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: '[validation-error]',
  templateUrl: './validation-error.component.html',
  styleUrls: ['./validation-error.component.less'],
})
export class ValidationErrorComponent {
  @Input() mapKeys: { [key: string]: string } = {};
  @Input('validation-error') control: FormControlName;
  @Input('data-id') id: string;

  errors: string[];

  constructor(private translateService: TranslateService) {}

  @HostBinding('class.has-error') get hasError() {
    if (!this.control) return false;
    if (this.control.touched && this.control.invalid && this.mapKeys) {
      this.errors = [];

      this.mapKeys['required'] =
        this.mapKeys['required'] ||
        this.translateService.instant('formField.RequiredError');

      for (let error in this.control.errors) {
        this.errors.push(this.mapKeys[error]);
      }
    } else {
      this.errors = [];
    }

    return null;
  }
}
