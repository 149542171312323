import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DrawerComponent } from './drawer.component';
import { DrawerService } from './service/drawer.service';

@NgModule({
  declarations: [DrawerComponent],
  imports: [CommonModule],
  exports: [DrawerComponent],
})
export class DrawerModule {
  static forRoot(): ModuleWithProviders<DrawerModule> {
    return {
      ngModule: DrawerModule,
      providers: [DrawerService],
    };
  }
}
