<ng-container *ngIf="data">
  <div class="subscribe__content" [class.subscribe__content--loading]="isBusy">
    <h3 class="subscribe__title">{{ data.title }}</h3>
    <p class="subscribe__description">{{ data.description }}</p>

    <form #subscribeForm="ngForm" class="subscribe__form" [formGroup]="form">
      <fieldset
        role="group"
        aria-labelledby="checklist_title"
        class="subscribe-list"
        *ngIf="model?.length > 0"
      >
        <legend id="checklist_title" class="subscribe-list__title">
          {{ 'mailchimpPanel.GroupsTitleText' | translate }}
        </legend>

        <ng-container formArrayName="checkList">
          <label
            class="form-field"
            *ngFor="let group of checkboxFormArray.controls; let i = index"
            (keydown.enter)="onEnter($event, i)"
          >
            <input type="checkbox" [formControlName]="i"/>
            <span class="checkmark"></span>
            {{ model[i].name }}
          </label>
          <div
            class="validation-error"
            role="alert"
            tabindex="0"
            aria-describedby="checklist_title"
            *ngIf="
              !form.controls.checkList.valid && form.controls.checkList.touched
            "
          >
            {{ 'mailchimpPanel.checkListError' | translate }}
          </div>
        </ng-container>
      </fieldset>

      <div class="input-n-btn-block">
        <div>
          <div
            form-field
            [mapKeys]="{ pattern: 'formField.EmailError' | translate }"
          >
            <input
              tabindex="0"
              name="email"
              formControlName="email"
              type="email"
              required
              aria-required="true"
              [placeholder]="'mailchimpPanel.WatermarkText' | translate"
              [attr.aria-invalid]="emailIsInvalidAndTouched"
              [attr.aria-label]="'mailchimpPanel.Email.Label' | translate"
              class="subscribe__input--email"
            />
          </div>

          <label
            class="subscribe__agreements"
            form-field
            (keyup.enter)="agreeCheck($event)"
          >
            <input
              #agreedRef
              type="checkbox"
              [attr.aria-invalid]="agreedIsInvalidAndTouched"
              formControlName="agreed"
            />
            <span class="checkmark"></span>
            <span
              (click)="agreementClick($event)"
              (keyup.enter)="agreementClick($event)"
              (keyup.space)="agreementClick($event)"
              [innerHtml]="'mailchimpPanel.AgreementText' | translate"
            ></span>
          </label>
        </div>

        <button ubl-button class="submit register-btn" (click)="onSubscribe()">
          {{ 'mailchimpPanel.ButtonText' | translate }}
        </button>
      </div>

      <div
        class="subscribe-message"
        *ngIf="isSuccess !== null"
        [class.subscribe-message--success]="isSuccess"
        [class.subscribe-message--error]="!isSuccess"
      >
        <i class="icon icon-alert"></i>
        <p [attr.role]="isSuccess ? 'status' : 'alert'">
          {{
          'mailchimpPanel.Subscribe' + (isSuccess ? 'Success' : 'Error')
            | translate
          }}
        </p>
      </div>
    </form>

    <span class="loader" [hidden]="!isBusy"></span>
  </div>

  <i
    *ngIf="popup"
    class="icon icon-close"
    role="button"
    tabindex="0"
    attr.aria-label="{{ 'popup.Close.Text' | translate }}"
    (click)="close.emit()"
    (keyup.enter)="close.emit()"
    (keyup.space)="close.emit()"
    #closeBtn
  ></i>
</ng-container>
