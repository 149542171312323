import { ErrorHandler, Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable()
export class AppErrorHandler implements ErrorHandler {
    handleError(error: any): void {
        try {
            if (!environment.production) 
            {
                const style = 'background: #222; color: #bada55';
                console.group('%cAppErrorHandler', style);
                console.error('%c' + error.message, style);
                console.error('%c' + error.stack, style);
                console.groupEnd();
            }
        } catch {
            console.error(error);
        }
    }
}