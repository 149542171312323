<ubl-subscribe
  *ngIf="!popup"
  [data]="subscribeData"
  (showPrivacy)="showAgreements($event)"
></ubl-subscribe>

<ubl-subscribe
  *ngIf="popup"
  [data]="subscribeData"
  [popup]="popup"
  (showPrivacy)="showAgreements($event)"
  (close)="closeHandler()"
  cdkTrapFocus
></ubl-subscribe>
