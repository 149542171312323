import { Component, Input } from '@angular/core';
import { IStackedNavigationItem } from 'src/app/ui/main-layout-components/stacked-navigation/stacked-navigation.component';

@Component({
  selector: 'app-mobile-menu',
  templateUrl: './mobile-menu.component.html',
  styleUrls: ['./mobile-menu.component.less']
})
export class MobileMenuComponent {
  @Input() data: IStackedNavigationItem[];
}
