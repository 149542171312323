import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormFieldComponent } from './form-field.component';
import { ValidationErrorComponent } from './components/validation-error/validation-error.component';

@NgModule({
  declarations: [FormFieldComponent, ValidationErrorComponent],
  imports: [
    CommonModule,
  ],
  exports: [FormFieldComponent]
})
export class FormFieldModule { }
