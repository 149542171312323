import {
  Component,
  ViewEncapsulation,
  Input,
  ContentChild,
  HostBinding,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  ElementRef,
} from '@angular/core';
import { FormControlName } from '@angular/forms';

import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: '[form-field]',
  templateUrl: './form-field.component.html',
  styleUrls: ['./form-field.component.less'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'form-field',
    '[id]': 'id',
  },
})
export class FormFieldComponent {
  @Input() mapKeys: { [key: string]: string } = {};

  @ContentChild(FormControlName, { static: true }) control: FormControlName;
  @ContentChild(FormControlName, { static: true, read: ElementRef })
  controlEl: ElementRef<HTMLInputElement>;
  @HostBinding('class.form-field--has-value') hasValue: boolean;
  @HostBinding('class.form-field--focused') focused: boolean;

  id: string;

  constructor(private cdRef: ChangeDetectorRef) {}

  ngOnInit() {
    this.id = uuidv4();
    if (this.controlEl.nativeElement) {
      this.controlEl.nativeElement.addEventListener(
        'focus',
        this.handleFocus.bind(this)
      );
      this.controlEl.nativeElement.addEventListener(
        'blur',
        this.handleBlur.bind(this)
      );
    }
  }

  ngDoCheck() {
    const prevAndCurrentFalsy = this.control.value && !this.hasValue;
    const prevTrueAndCurrentFalse = this.hasValue && !this.control.value;

    if (
      !prevAndCurrentFalsy &&
      !prevTrueAndCurrentFalse &&
      this.control.hasError
    )
      this.cdRef.markForCheck();
    if (!prevAndCurrentFalsy && !prevTrueAndCurrentFalse) return;

    if (prevAndCurrentFalsy) this.hasValue = true;
    if (prevTrueAndCurrentFalse) this.hasValue = false;

    this.cdRef.markForCheck();
  }

  private handleFocus(ev: MouseEvent) {
    this.focused = true;
  }

  private handleBlur(ev: MouseEvent) {
    this.focused = false;
  }
}
