import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
} from '@angular/core';
import {
  SubscribeService,
  SubscribeServiceResponseStatus,
  ISubscribeModel,
} from '../../service/subscribe.service';
import { SafeHtml } from '@angular/platform-browser';
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
  UntypedFormArray,
  ValidationErrors,
  UntypedFormBuilder,
  ReactiveFormsModule,
  FormsModule,
} from '@angular/forms';
import { A11yModule, LiveAnnouncer } from '@angular/cdk/a11y';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { ButtonModule, LinkModule } from 'src/app/dynamic-module/lib/public_api';
import { CheckboxModule } from 'src/app/dynamic-module/lib/ui-kit/checkbox/checkbox.module';
import { FormFieldModule } from 'src/app/shared/components/form-field/form-field.module';

export interface ISubscribeData {
  description: string;
  title: string;
  agreementText: ISubscribeAgreementData;
  lists: ISubscribeListItem[];
}

export interface ISubscribeListItem {
  listId: string;
  listName: string;
  groups: string[];
}

export interface ISubscribeAgreementData {
  title: string;
  description: SafeHtml;
}

interface IViewModel {
  name: string;
  id: string;
  checked: boolean;
  groups: string[];
}

@Component({
  standalone: true,
  selector: 'ubl-subscribe',
  templateUrl: './subscribe.component.html',
  styleUrls: ['./subscribe.component.less'],
  host: {
    autocomplete: 'off',
  },
  imports: [
    CommonModule,
    TranslateModule,
    ReactiveFormsModule,
    FormsModule,
    LinkModule,
    ButtonModule,
    CheckboxModule,
    FormFieldModule,
    A11yModule,
  ],
})
export class SubscribeComponent implements OnInit {
  @Input() data: ISubscribeData;
  @Input() agreed: boolean;
  @Input() agreedControlNoChanges: boolean;
  @Input() popup = false;
  @Output() showPrivacy = new EventEmitter();
  @Output() subscribed = new EventEmitter();
  @Output() close = new EventEmitter();
  @ViewChild('privacyText') privacyText: ElementRef;
  @ViewChild('closeBtn') closeBtn: ElementRef;

  get emailIsInvalid() {
    return this.form.controls.email.invalid;
  }

  get emailIsInvalidAndTouched() {
    return this.form.controls.email.invalid && this.form.controls.email.touched;
  }

  get agreedIsInvalidAndTouched() {
    return (
      this.form.controls.agreed.invalid && this.form.controls.agreed.touched
    );
  }

  emailPattern = '[A-Za-z0-9._%-]+@[A-Za-z0-9._%-]+\\.[a-z]{2,3}';
  form: UntypedFormGroup;
  isBusy: boolean;
  isSuccess?: boolean = null;
  model: IViewModel[];

  get checkboxFormArray() {
    return this.form.controls.checkList as UntypedFormArray;
  }

  constructor(
    public elRef: ElementRef,
    private formBuilder: UntypedFormBuilder,
    private subscribeService: SubscribeService,
    private liveAnnouncer: LiveAnnouncer,
    private translate: TranslateService
  ) {
    this.form = this.formBuilder.group({
      checkList: new UntypedFormArray([], this.isPartlyChecked),
      email: new UntypedFormControl('', [
        Validators.maxLength(400),
        Validators.pattern(this.emailPattern),
      ]),
      agreed: new UntypedFormControl(false, [Validators.requiredTrue]),
    });
  }

  ngOnInit() {
    this.model =
      this.data &&
      this.data.lists &&
      this.data.lists
        .map((i) => {
          if (!i.listId || !i.listName) return null;
          return {
            name: i.listName,
            id: i.listId,
            groups: i.groups,
            checked: false,
          };
        })
        .filter((i) => i !== null);

    this.model.forEach((el) => {
      this.checkboxFormArray.push(new UntypedFormControl(false));
    });
  }

  ngAfterViewInit() {
    if (this.popup) {
      this.closeBtn.nativeElement.focus();
    }
  }

  agreementClick(e: Event) {
    const target = <HTMLElement>e.target;
    if (target?.getAttribute('role') === 'button') {
      e.stopPropagation();
      e.preventDefault();
      this.showPrivacy.emit(target);
    }
  }

  onEnter(e: KeyboardEvent, i: number) {
    e.preventDefault();
    const checkbox = this.checkboxFormArray.controls[i];
    checkbox.setValue(!checkbox.value);
  }

  agreeCheck(e: KeyboardEvent) {
    e.preventDefault();
    const agreeCheckbox = this.form.controls.agreed;
    agreeCheckbox.setValue(!agreeCheckbox.value);
  }

  async onSubscribe() {
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      this.liveAnnouncer.announce(
        this.translate.instant('mailchimpPanel.Error.Form')
      );
    } else {
      this.liveAnnouncer.clear();
      this.isBusy = true;
      this.isSuccess = null;

      const lists = this.form.value.checkList
        .map((checked, i) => {
          if (checked) {
            return {
              id: this.model[i].id,
              groups: this.model[i].groups,
            };
          }
          return null;
        })
        .filter((v) => v !== null);

      const postModel: ISubscribeModel = {
        email: this.form.value.email,
        lists,
        agreementText: {
          title: this.data.agreementText.title,
          description: this.data.agreementText.description as string,
        },
      };
      const result = await this.subscribeService.subscribe(postModel);

      if (result === SubscribeServiceResponseStatus.statussubscribed) {
        const form = this.form;

        this.isSuccess = true;
        this.subscribed.emit();

        this.form.reset();

        this.model.forEach((value) => (value.checked = false));

        form.markAsUntouched();
        form.markAsPristine();
        form.updateValueAndValidity();
      } else {
        this.isSuccess = false;
      }

      this.isBusy = false;
    }
  }

  someListIdSelected() {
    return this.model.some((i) => i.checked);
  }

  isPartlyChecked(arr: UntypedFormArray): ValidationErrors {
    return arr.controls.length > 0 &&
      arr.controls.filter((s) => s.value).length === 0
      ? {
          invalidSize: true,
        }
      : null;
  }
}
