<div class="content" cdkTrapFocus>
  <i
    class="icon icon-close"
    role="button"
    tabindex="0"
    attr.aria-label="{{ 'popup.Close.Text' | translate }}"
    (click)="close()"
    (keyup.enter)="close()"
    (keyup.space)="close()"
    #closeBtn
  ></i>

  <ng-template #container></ng-template>
</div>
