<li
  class="stacked-navigation__item"
  [ngClass]="{ expanded: item.isExpanded, active: item.isActive }"
  *ngFor="let item of data"
>
  <div class="stacked-navigation__item-title">
    <ubl-link
      [data]="Link.fromUrlWithTarget(item.url, item.linkTarget)"
      (click)="handleLinkClick(item)"
    >
      {{ item.title }}
    </ubl-link>

    <i
      class="icon"
      [class.icon-chevron-right]="!item.isExpanded"
      [class.icon-chevron-up]="item.isExpanded"
      (click)="handleClick(item)"
      role="button"
      tabindex="0"
      [attr.aria-expanded]="item.isExpanded"
      aria-haspopup="true"
      *ngIf="item.children?.length"
    >
    </i>
  </div>
  <ul
    class="stacked-navigation__children"
    [stacked-navigation]="item?.children"
    *ngIf="item?.children.length && item.isExpanded"
  ></ul>
</li>
