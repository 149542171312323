import { Component, OnInit, Input, ChangeDetectionStrategy } from '@angular/core';
import { ISiteFooterSettings } from 'src/app/shared/interface/site-settings';
import { Link } from 'src/app/dynamic-module/lib/public_api';


@Component({
  selector: 'footer[app-footer]',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'footer'
  }
})
export class FooterComponent implements OnInit {
  @Input() settings: ISiteFooterSettings;
  Link = Link;

  ngOnInit() {
  }
}
