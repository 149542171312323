export const config = {
    desktopMediaQuery: '(min-width: 769px)',
    api: "/ubaseline/api",
    translateApi: "ubaseline/api/localization/getAll",
    searchApi: "/ubaseline/api/BsfSearchApi/",
    autoSuggestApi: "/ubaseline/api/searchapi/autocomplete",
    pages: {
      searchPage: () => import('src/app/ui/pages/search-result/search-result.module').then(m => m.SearchResultModule),
      newsOverviewPage: () => import('src/app/ui/pages/news-overview/news-overview.module').then(m => m.NewsOverviewModule),
      newsDetailsPage: () => import('src/app/ui/pages/news-details/news-details.module').then(m => m.NewsDetailsModule),
      articlePage: () => import('src/app/ui/pages/article/article.module').then(m => m.ArticleModule),
      articleOverviewPage: () => import('src/app/ui/pages/article-overview/article-overview.module').then(m => m.ArticleOverviewModule),
      sectionPage: () => import('src/app/ui/pages/section-page/section-page.module').then(m => m.SectionPageModule),
      toolboxSectionPage: () => import('src/app/ui/pages/toolbox-section-page/toolbox-section-page.module').then(m => m.ToolboxSectionPageModule),
      eventPage: () => import('src/app/ui/pages/event-page/event-page.module').then(m => m.EventPageModule),
      eventOverviewPage: () => import('src/app/ui/pages/event-overview-page/event-overview-page.module').then(m => m.EventOverviewPageModule),
      guideOverviewPage: () => import('src/app/ui/pages/guide-overview-page/guide-overview-page.module').then(m => m.GuideOverviewPageModule),
      guidePage: () => import('src/app/ui/pages/guide-page/guide-page.module').then(m => m.GuidePageModule),
      guideSectionPage: () => import('src/app/ui/pages/guide-section-page/guide-section-page.module').then(m => m.GuideSectionPageModule),
      guideOrderPage: () => import('src/app/ui/pages/order-guide-page/order-guide-page.module').then(m => m.OrderGuidePageModule),
      previousEventOverviewPage: () => import('src/app/ui/pages/event-overview-page/event-overview-page.module').then(m => m.EventOverviewPageModule),
      contactPage: () => import('src/app/ui/pages/contact-page/contact-page.module').then(m => m.ContactPageModule),
      toolboxPage: () => import('src/app/ui/pages/toolbox-page/toolbox-page.module').then(m => m.ToolboxPageModule),
    }
};
