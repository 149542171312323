import { Injectable, Inject } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { DOCUMENT } from '@angular/common';

export enum DrawerState {
  closed = 1, opened
}

@Injectable({
  providedIn: 'root'
})
export class DrawerService {
  state$ = new BehaviorSubject(DrawerState.closed);

  constructor(@Inject(DOCUMENT) private document: Document)
  {
  }

  open()
  {
    this.state$.next(DrawerState.opened);
    this.document.body.classList.add('no-scroll');
  }

  close()
  {
    this.state$.next(DrawerState.closed);
    this.document.body.classList.remove('no-scroll');
  }

  toggle()
  {
    this.state$.value === DrawerState.opened ? this.close() : this.open();
  }
}
