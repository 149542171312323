import { Injectable } from '@angular/core';
import { TranslateLoader } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { config } from '../app.config';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class TranslationsLoader implements TranslateLoader {
  constructor(private http: HttpClient) {}

  getTranslation(lang: string): Observable<any> {
    return this.http.get(config.translateApi).pipe(
      map((res) => {
        if (res) {
          for(let key in res) {
						const val = res[key];
						delete res[key];
						res[key[0].toLowerCase() + key.slice(1)] = val;
					}
        }
        return res;
      })
    );
  }
}
