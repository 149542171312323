import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
} from '@angular/core';
import {
  ISubscribeAgreementData,
  SubscribeComponent,
} from '../subscribe/component/subscribe/subscribe.component';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { A11yModule } from '@angular/cdk/a11y';

@Component({
  standalone: true,
  selector: 'app-subscribe-modal',
  templateUrl: './subscribe-modal.component.html',
  styleUrls: ['./subscribe-modal.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    SubscribeComponent,
    SharedModule,
    TranslateModule,
    A11yModule,
  ],
})
export class SubscribeModalComponent implements OnInit {
  @Input() data: ISubscribeAgreementData;
  constructor() {}

  ngOnInit(): void {}
}
