import { IButtonData } from './button';

export interface ILink {
  url: string;
  innerRoute: boolean;
  target: LinkTargetType;
  queryParams: { [key: string]: string };
  label: string;
  name?: string;
}

export enum UrlType {
  Node = 0,
  Media = 1,
  External = 2,
}

export type LinkTargetType = '_blank' | '_self' | '_parent' | '_top';

export class Link implements ILink {
  queryParams = {};

  constructor(
    public url: string,
    public label: string,
    public innerRoute: boolean,
    public target: LinkTargetType
  ) {
    if (this.hasProtocol(url)) this.innerRoute = false;

    if (innerRoute) {
      const { path, params } = this.splitUrlToPathWithParams(url);
      this.url = path;
      this.queryParams = params;
    }
  }

  static fromButtonData(data?: IButtonData) {
    if (!data) {
      console.log(`Link data is ${data}`);
      return;
    }

    const innerRoute = data.type !== UrlType.External;
    let target = (data.target && data.target) || '_self';
    if (this.isFile(data.url) && data.target != '_blank') {
      target = '_top';
    }

    return new Link(data.url, data.name, innerRoute, target);
  }

  static fromUrlWithTarget(url: string, target: LinkTargetType): IButtonData {
    return new Link(url, '', true, target);
  }

  private splitUrlToPathWithParams(url: string) {
    let path = '';
    const params = {};
    const parts = url.split('?');
    path = parts[0];

    if (parts[1]) {
      parts[1].split('&').forEach((segment) => {
        const [key, val] = segment.split('=');
        params[key] = val;
      });
    }

    return { path, params };
  }

  hasProtocol(url: string) {
    return url && url.indexOf('http') === 0;
  }

  private static isFile(url: string) {
    const regex = /\.\w{2,4}$/gm;
    return url.match(regex);
  }
}
