import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { config } from '../app.config';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import { IStackedNavigationItem } from '../ui/main-layout-components/stacked-navigation/stacked-navigation.component';

export interface INavigationItem {
  title: string;
  url: string;
  isActive?: boolean;
}
@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  stakedNavigation$: Observable<IStackedNavigationItem[]>;
  mobileNavigation$: Observable<IStackedNavigationItem[]>;

  private stackedNavigation: BehaviorSubject<any[]>;

  constructor(private http: HttpClient) {
    this.stackedNavigation = new BehaviorSubject([]);
    this.stakedNavigation$ = this.stackedNavigation.asObservable();

    this.mobileNavigation$ = this.http
      .get<{ items: IStackedNavigationItem[] }>(
        `${config.api}/BsfNavigation/GetMobileTreeNavigation`
      )
      .pipe(
        catchError(() => of({ items: [] })),
        map((response) => response.items)
      );
  }

  getTopNavigation(): Observable<INavigationItem[]> {
    return this.http.get<INavigationItem[]>(
      `${config.api}/navigation/getTopNavigation`
    );
  }

  setDrawerNavigation(data: IStackedNavigationItem[]) {
    this.stackedNavigation.next(data);
  }

  private topNavigation2stackedNavigation(
    data: INavigationItem[]
  ): IStackedNavigationItem[] {
    return data.map((i) => {
      return {
        children: [],
        isAdditionalLink: false,
        isActive: i.isActive,
        isExpanded: false,
        linkTarget: '',
        title: i.title,
        url: i.url,
      };
    });
  }
}
