import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { combineLatest } from 'rxjs';
import { AnimationsService } from 'src/app/service/animations.service';
import { DynamicComponent } from '../dynamic/dynamic.component';

@Component({
  selector: 'app-panels',
  templateUrl: './panels.component.html',
  styleUrls: ['./panels.component.less'],
  host: {
    '[class._loaded]': '!isPageAnimating || loaded',
  },
})
export class PanelsComponent implements AfterViewInit {
  loaded = false;
  @Input() items: any[];
  @Input() markLast = false;
  @Output() loadComplete = new EventEmitter();

  @ViewChildren('panels') panels: QueryList<DynamicComponent>;

  isPageAnimating = false;

  constructor(animationsService: AnimationsService) {
    this.isPageAnimating = animationsService.isPageAnimating;
  }

  ngAfterViewInit(): void {
    combineLatest(this.panels.map((l) => l.loadedState$)).subscribe((x) => {
      this.loaded = true;
      this.loadComplete.emit();
    });
  }
}
