export const getEmptyGrid = () => {
  return {
    rows: [
      {
        areas: [
          {
            columnSpan: 3,
            items: [],
            rowSpan: 1,
          },
          {
            columnSpan: 9,
            items: [],
            rowSpan: 1,
          }
        ],
        columnSpan: 12,
        rowSpan: 1,
      }
    ]
  }
}