import { Component, Output, Input, HostListener, ElementRef, ɵɵresolveBody } from '@angular/core';
import { EventEmitter } from '@angular/core';

@Component({
  selector: 'button[scroll-top]',
  templateUrl: './scroll-top.component.html',
  styleUrls: ['./scroll-top.component.less'],
  host: {
    type: 'button',
    '(click)':'scrollToTop()',
    '[hidden]': '!windowScrolled'
  }
})
export class ScrollTopComponent {
  @Output() onScrolledToTop = new EventEmitter();
  @Input() show: boolean;
  windowScrolled: boolean;

  constructor(
    private elRef: ElementRef<HTMLDivElement>
  ) {}

  @HostListener("window:scroll", [])
  onWindowScroll()
  {
    if (window.pageYOffset > 1000 || document.documentElement.scrollTop > 1000 || document.body.scrollTop > 1000) {
      this.windowScrolled = true;
    }
    else if (this.windowScrolled && window.pageYOffset < 10 || document.documentElement.scrollTop < 10 || document.body.scrollTop < 10) {
      this.windowScrolled = false;
    }
  }

  scrollToTop()
  {
    this.smoothScroll();
    this.elRef.nativeElement.blur();
  }

  smoothScroll()
  {
    let currentScroll = document.documentElement.scrollTop || document.body.scrollTop;

    if (currentScroll > 0)
    {
      window.requestAnimationFrame(() => this.smoothScroll());
      window.scrollTo(0, currentScroll - (currentScroll / 4));
    } else {
      document.body.focus();
      this.onScrolledToTop.next(currentScroll);
    }
  }
}
