import {
  Component,
  ViewContainerRef,
  ViewChild,
  ComponentFactoryResolver,
  Type,
  ElementRef,
} from '@angular/core';

export interface IPopupContext {
  [key: string]: any;
  title?: string;
  initiator?: Element;
}

@Component({
  selector: 'app-container',
  templateUrl: './container.component.html',
  styleUrls: ['./container.component.less'],
  host: {
    role: 'dialog',
    class: 'popup',
    '[class.popup--open]': 'state === "open"',
    '[attr.aria-modal]': 'true',
    '(window:keydown)': 'onKeyDownHandler($event)',
    '(click)': 'onOutsideClick($event)',
  },
})
export class ContainerComponent {
  @ViewChild('container', { static: true, read: ViewContainerRef })
  private container: ViewContainerRef;

  @ViewChild('closeBtn') closeBtn: ElementRef;

  state: 'open' | 'closed' = 'closed';
  title: string;
  initiator: HTMLElement;

  private currentComponent: any;

  constructor(private componentFactoryResolver: ComponentFactoryResolver) {}

  create<T>(
    component: Type<T>,
    context: IPopupContext,
    lazyFactoryResolver?: ComponentFactoryResolver
  ) {
    this.container.clear();

    const resolver = lazyFactoryResolver
      ? lazyFactoryResolver
      : this.componentFactoryResolver;
    const factory = resolver.resolveComponentFactory(component);

    const componentRef = this.container.createComponent(factory);

    if (context) {
      Object.keys(context).forEach((key) => {
        componentRef.instance[key] = context[key];
      });

      if (context.title) {
        this.title = context.title;
      }
      if (context.initiator && context.initiator instanceof HTMLElement) {
        this.initiator = context.initiator;
      }
    }

    this.currentComponent = componentRef;
    this.state = 'open';

    setTimeout(() => {
      this.closeBtn.nativeElement.focus();
    }, 0);

    return componentRef;
  }

  close() {
    this.focusToInitiator();

    this.state = 'closed';
    this.title = '';
    this.initiator = null;
    this.currentComponent && this.currentComponent.destroy();
  }

  onKeyDownHandler(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this.close();
    }
  }

  onOutsideClick(event: MouseEvent) {
    if (event.target === event.currentTarget) {
      this.close();
    }
  }

  private focusToInitiator() {
    if (this.initiator) {
      setTimeout(
        (initiator) => {
          initiator.focus();
        },
        0,
        this.initiator
      );
    }
  }
}
