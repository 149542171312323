import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AnimationsService {
  private _canStart = new Subject();

  canStart = this._canStart.asObservable();
  isPageAnimating = false;

  enableAnimation(value: boolean) {
    this.isPageAnimating = value;
  }

  startAnimating() {
    this._canStart.next();
  }

  constructor() {}
}
